.uxf-typo-h1 {
    @apply text-desktopH1 font-semibold font-title uppercase tracking-wide;
}

.uxf-typo-h2 {
    @apply text-desktopH2 font-semibold font-title uppercase tracking-wide;
}

.uxf-typo-h3 {
    @apply text-desktopH3 font-semibold font-title uppercase tracking-wide;
}

.uxf-typo-h4 {
    @apply text-desktopH4 font-semibold font-title uppercase tracking-wide;
}

.uxf-typo-h5 {
    @apply text-desktopH5 font-semibold font-title uppercase tracking-wide;
}

.uxf-typo-h6 {
    @apply text-desktopH6 font-semibold tracking-normal;
}

.uxf-typo-body {
    @apply text-body tracking-normal;
}

.uxf-typo-body2 {
    @apply text-body2 tracking-normal;
}

.uxf-typo-button {
    @apply text-body font-semibold tracking-normal;
}

.uxf-typo-caption {
    @apply text-caption tracking-normal;
}

.uxf-typo-medium {
    @apply text-medium font-medium tracking-normal;
}

.uxf-typo-medium2 {
    @apply text-medium2 font-medium tracking-normal;
}

.uxf-typo-overline {
    @apply text-overline font-semibold tracking-normal;
}
