.uxf-input {
    @apply rounded-sm shadow-none;

    &__wrapper {
        @apply rounded-sm;
    }

    &.is-focused {
        .uxf-input__wrapper {
            @apply !border-primary-600 !ring-primary-600;
        }
    }
}

.uxf-text-input--gray {
    .uxf-input {
        :root .light & {
            @apply bg-gray-100;

            input:-webkit-autofill {
                &,
                &:hover,
                &:focus {
                    -webkit-box-shadow: inset 0 0 0 1000px theme("colors.gray.100");
                    box-shadow: inset 0 0 0 1000px theme("colors.gray.100");
                    background-color: theme("colors.gray.100");
                }
            }
        }
    }
}
