.uxf-flash-messages {
    @apply pointer-events-none fixed inset-0 z-50 flex items-end p-6;

    &__list-wrapper {
        @apply flex w-full max-w-sm flex-col space-y-4 items-start sm:justify-start;
    }
}

.uxf-flash-message {
    @apply pointer-events-auto rounded-lg transition px-5 py-4 shadow backdrop-blur text-sm min-w-[15rem]
        is-hoverable:cursor-pointer;

    &--success {
        @apply bg-primary-600 is-hoverable:bg-primary-700 text-black;
    }

    &--error {
        @apply bg-error-500 is-hoverable:bg-error-700 text-white;
    }

    &--warning {
        @apply bg-warning-500 is-hoverable:bg-warning-700 text-white;
    }

    &--info {
        @apply bg-gray-500 is-hoverable:bg-gray-700 text-white;
    }
}

.has-unpaid-meals .uxf-flash-messages {
    @apply pb-24 md:pb-6;
}
