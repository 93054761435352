.uxf-modal {
    &__panel {
        border-top-left-radius: theme("borderRadius.sm");
        border-top-right-radius: theme("borderRadius.sm");

        @screen sm {
            border-radius: theme("borderRadius.sm");
        }
    }
}
