.uxf-chip {
    @apply inline-flex items-center rounded-full py-0.5 px-2 text-xs max-w-full;

    &.has-button {
        @apply pr-1;
    }

    &--size-large {
        @apply text-sm rounded-full px-2.5;

        .uxf-chip__button {
            @apply h-4 w-4;
        }
    }

    &__text {
        @apply truncate;
    }

    &__button {
        @apply ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full;

        svg {
            @apply h-2 w-2;
        }
    }

    &--color-default {
        :root .light & {
            @apply text-gray-700 bg-gray-200;

            .uxf-chip__button {
                @apply hover:bg-gray-200;
            }
        }

        :root .dark & {
            @apply text-gray-100 bg-gray-700;

            .uxf-chip__button {
                @apply hover:bg-gray-800;
            }
        }
    }

    &--color-orange {
        :root .light & {
            @apply text-orange-700 bg-orange-100;

            .uxf-chip__button {
                @apply hover:bg-orange-200;
            }
        }

        :root .dark & {
            @apply text-orange-100 bg-orange-700;

            .uxf-chip__button {
                @apply hover:bg-orange-800;
            }
        }
    }

    &--color-red {
        :root .light & {
            @apply text-red-700 bg-red-100;

            .uxf-chip__button {
                @apply hover:bg-red-200;
            }
        }

        :root .dark & {
            @apply text-red-100 bg-red-700;

            .uxf-chip__button {
                @apply hover:bg-red-800;
            }
        }
    }

    &--color-yellow {
        :root .light & {
            @apply text-yellow-700 bg-yellow-100;

            .uxf-chip__button {
                @apply hover:bg-yellow-200;
            }
        }

        :root .dark & {
            @apply text-yellow-100 bg-yellow-700;

            .uxf-chip__button {
                @apply hover:bg-yellow-800;
            }
        }
    }

    &--color-green {
        :root .light & {
            @apply text-green-700 bg-green-100;

            .uxf-chip__button {
                @apply hover:bg-green-200;
            }
        }

        :root .dark & {
            @apply text-green-100 bg-green-700;

            .uxf-chip__button {
                @apply hover:bg-green-800;
            }
        }
    }

    &--color-blue {
        :root .light & {
            @apply text-blue-700 bg-blue-100;

            .uxf-chip__button {
                @apply hover:bg-blue-200;
            }
        }

        :root .dark & {
            @apply text-blue-100 bg-blue-700;

            .uxf-chip__button {
                @apply hover:bg-blue-800;
            }
        }
    }

    &--color-indigo {
        :root .light & {
            @apply text-indigo-700 bg-indigo-100;

            .uxf-chip__button {
                @apply hover:bg-indigo-200;
            }
        }

        :root .dark & {
            @apply text-indigo-100 bg-indigo-700;

            .uxf-chip__button {
                @apply hover:bg-indigo-800;
            }
        }
    }

    &--color-purple {
        :root .light & {
            @apply text-purple-700 bg-purple-100;

            .uxf-chip__button {
                @apply hover:bg-purple-200;
            }
        }

        :root .dark & {
            @apply text-purple-100 bg-purple-700;

            .uxf-chip__button {
                @apply hover:bg-purple-800;
            }
        }
    }

    &--color-pink {
        :root .light & {
            @apply text-pink-700 bg-pink-100;

            .uxf-chip__button {
                @apply hover:bg-pink-200;
            }
        }

        :root .dark & {
            @apply text-pink-100 bg-pink-700;

            .uxf-chip__button {
                @apply hover:bg-pink-800;
            }
        }
    }

    &--color-primary {
        @apply text-primary-900 bg-primary-200;

        .uxf-chip__button {
            @apply hover:bg-primary-200;
        }
    }
}
