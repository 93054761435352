.uxf-toggle {
    @apply relative inline-flex shrink-0 h-6 w-12 items-center rounded-full p-0.5 outline-none ring-offset-2
        focus-visible:ring-2 [&>*]:active:w-[30px];

    &__wrapper {
        @apply flex items-center justify-between space-x-4 p-4;

        &--reversed {
            @apply flex-row-reverse space-x-0;

            .uxf-toggle__label {
                @apply pr-4;
            }
        }
    }

    &__label {
        @apply w-full;

        :root .light & {
            @apply text-lightHigh font-medium;
        }

        :root .dark & {
            @apply text-darkMedium;
        }
    }

    &__inner {
        @apply inline-block h-5 w-5 transform rounded-full bg-white transition transition-all;
    }

    &.is-disabled {
        @apply pointer-events-none;
    }

    &.is-selected {
        @apply [&>*]:translate-x-6 [&>*]:active:translate-x-[14px];
    }

    :root .light & {
        @apply bg-gray-200 focus-visible:ring-lightHigh;

        &__inner {
            @apply bg-white;
        }

        &.is-selected {
            @apply bg-lightHigh;
        }

        &.is-disabled {
            @apply bg-gray-100;

            .uxf-toggle__inner {
                @apply bg-gray-400;
            }
        }
    }

    :root .dark & {
        @apply bg-gray-400 focus-visible:ring-lightHigh focus-visible:ring-offset-gray-900;

        &.is-selected {
            @apply bg-lightHigh;
        }

        &__inner {
            @apply bg-gray-900;
        }

        &.is-disabled {
            @apply bg-gray-700;

            .uxf-toggle__inner {
                @apply bg-gray-500;
            }
        }
    }
}
