@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.uxf-button {
    @apply inline-flex items-center justify-center rounded-md font-semibold shadow-sm select-none
        focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 transition-colors;

    .uxf-icon {
        @apply h-6;
    }

    &__text {
        @apply truncate;
    }

    &--full-width {
        @apply w-full flex flex-row justify-center;
    }

    &.is-disabled {
        @apply pointer-events-none;
    }

    &.uxf-button--icon-button.is-loading {
        &::after {
            @apply ml-0;
        }
    }

    &.is-loading {
        @apply pointer-events-none;

        &::after {
            animation: spinner 0.8s linear infinite;
            border-radius: 50%;
            border-right: 2px solid transparent;
            border-top: 2px solid;
            content: "";
            height: 20px;
            margin-left: 8px;
            width: 20px;
        }

        &.uxf-button--icon-button::after {
            margin-left: 0;
        }

        .uxf-icon {
            @apply hidden;
        }
    }

    &--size-xs {
        height: theme("inputSize.xs");

        @apply px-2.5 text-sm rounded-sm;

        &.is-loading::after {
            height: 14px;
            width: 14px;
        }

        &.uxf-button--icon-button {
            width: theme("inputSize.xs");

            @apply p-0;
        }

        .uxf-icon {
            @apply h-4 w-4;
        }
    }

    &--size-sm {
        height: theme("inputSize.sm");

        @apply px-3 text-base leading-4 rounded-sm;

        &.is-loading::after {
            height: 16px;
            width: 16px;
        }

        &.uxf-button--icon-button {
            width: theme("inputSize.sm");

            @apply p-0;
        }

        .uxf-icon {
            @apply h-4 w-4;
        }
    }

    &--size-default {
        height: theme("inputSize.default");

        @apply px-4 text-base rounded-sm;

        &.uxf-button--icon-button {
            width: theme("inputSize.default");

            @apply p-0;
        }
    }

    &--size-lg {
        height: theme("inputSize.lg");

        @apply px-4 text-base rounded-sm;

        &.uxf-button--icon-button {
            width: theme("inputSize.lg");

            @apply p-0;
        }
    }

    &--size-xl {
        height: theme("inputSize.xl");

        @apply px-6 text-base rounded-sm;

        &.uxf-button--icon-button {
            width: theme("inputSize.xl");

            @apply p-0;
        }
    }

    &--variant-default {
        @apply text-lightHigh;

        &.uxf-button--color-default {
            @apply text-lightHigh bg-primary is-hoverable:bg-primary-700 focus-visible:ring-primary;

            &.is-disabled {
                @apply bg-gray-400 text-gray-100;
            }
        }
    }

    &--variant-white {
        :root .light & {
            @apply border border-lightBorder bg-white is-hoverable:bg-gray-50;

            &.uxf-button--color-default {
                @apply text-gray-600;
            }

            &.uxf-button--color-success {
                @apply text-success-600;
            }

            &.uxf-button--color-error {
                @apply text-error-600;
            }

            &.is-disabled {
                @apply border-gray-300 text-gray-400;
            }
        }

        :root .dark & {
            @apply border-0 bg-lightBackground2Dark is-hoverable:bg-lightBackground2Dark;

            &.uxf-button--color-default {
                @apply text-darkMedium;
            }

            &.uxf-button--color-success {
                @apply text-success-400;
            }

            &.uxf-button--color-error {
                @apply text-error-400;
            }

            &.is-disabled {
                @apply text-gray-600;
            }
        }
    }

    &--variant-text {
        @apply shadow-none;

        :root .light & {
            &.uxf-button--color-default {
                @apply text-lightMedium is-hoverable:text-gray-700;
            }

            &.is-disabled {
                @apply text-gray-400;
            }
        }

        :root .dark & {
            &.uxf-button--color-default {
                @apply text-darkMedium is-hoverable:text-gray-300;
            }

            &.is-disabled {
                @apply text-gray-600;
            }
        }
    }
}
